
const OSS = require('ali-oss');

// 初始化OSS客户端。请将以下参数替换为您自己的配置信息。
const client = new OSS({
  region: 'oss-cn-beijing', // 示例：'oss-cn-hangzhou'，填写Bucket所在地域。
  Endpoint: "https://oss-cn-beijing.aliyuncs.com/huashengchong/hsc",
  accessKeyId: 'LTAI5t6DjeuwQDHh3DBytPyJ', // 确保已设置环境变量OSS_ACCESS_KEY_ID。
  accessKeySecret: 'yz8sCBVhCI0HFl6GQSsiHYUoO6mkna', // 确保已设置环境变量OSS_ACCESS_KEY_SECRET。
  bucket: 'huashengchong', // 示例：'my-bucket-name'，填写存储空间名称。
});

// 上传
export async function put (fileName, file) {
  try {
    let result = await client.put(fileName, file)
    return result
  } catch (err) {
    console.log(err)
  }
}

// 删除
export async function remove (fileName) {
  try {
    let result = await client.delete(fileName)
    return result
  } catch (err) {
    console.log(err)
  }
}